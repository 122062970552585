import React from "react";
import theme from "theme";
import { Theme, Text, Image, Link, LinkBox, Box, Section, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"works/stepform"} />
		<Helmet>
			<title>
				Кейс StepFORM | Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"StepFORM — это онлайн конструктор, где создают стильные опросы, онлайн калькуляторы и другие типы форм для сайтов, мессенджеров и соцсетей."} />
			<meta property={"og:title"} content={"Кейс StepFORM | Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"StepFORM — это онлайн конструктор, где создают стильные опросы, онлайн калькуляторы и другие типы форм для сайтов, мессенджеров и соцсетей."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
		</Helmet>
		<Box
			min-width="100px"
			border-width="22px"
			border-style="solid"
			border-color="--color-lightD2"
			display="flex"
			flex-direction="column"
			padding="12px 24px 24px 24px"
			md-border-width="0px"
		>
			<Components.Header>
				<Override slot="link" />
				<Override slot="link1" />
				<Override slot="link2" />
			</Components.Header>
			<Section quarkly-title="Cover" margin="50px 0 0px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="center"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					grid-column="2/3"
					display="flex"
					flex-direction="row"
					flex-wrap="wrap"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--headline2"
						width="100%"
						sm-font="--headline3"
					>
						StepFORM
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--lead28"
						max-width="760px"
						sm-font="--lead21"
						width="100%"
					>
						StepFORM is an online builder where you create stylish surveys, online calculators and other types of forms for websites, messengers and social networks.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="66%"
						padding="0px 16px 0px 0px"
						sm-width="100%"
						sm-height="auto"
						sm-min-height="auto"
						sm-margin="0px 0px 18px 0px"
					>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/favicon-stepform.png?v=2022-06-20T07:12:02.398Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
								srcSet="https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/favicon-stepform.png?v=2022-06-20T07%3A12%3A02.398Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/favicon-stepform.png?v=2022-06-20T07%3A12%3A02.398Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/favicon-stepform.png?v=2022-06-20T07%3A12%3A02.398Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/favicon-stepform.png?v=2022-06-20T07%3A12%3A02.398Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/favicon-stepform.png?v=2022-06-20T07%3A12%3A02.398Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/favicon-stepform.png?v=2022-06-20T07%3A12%3A02.398Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/favicon-stepform.png?v=2022-06-20T07%3A12%3A02.398Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,(max-width: 1536px) 100vw,100vw"
							/>
							<Link
								href="https://webask.io/"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								stepform.io
							</Link>
						</LinkBox>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Work Completion Date: July 1, 2020
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Made in 120 days
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="33%"
						sm-width="100%"
						sm-height="auto"
						sm-min-height="auto"
					>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							UX / UI design
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Product design
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Design management
						</Text>
					</Box>
				</Box>
			</Section>
			<Section quarkly-title="YoutubeVideo" margin="0 0 12px 0" inner-width="100%">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/60jsiWDVqOg"
					width="100%"
					border-width="1px"
					border-color="--color-lightD2"
					border-style="solid"
				/>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
					grid-gap="0px 24px"
				/>
				<Components.BaseUppercase>
					Goal
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Make a SaaS* form builder, calculators and quizzes with math, scoring and logic.
				</Text>
				<Text margin="16px 0px 0px 0px" font="--base" color="--grey" lg-grid-column="1/4">
					SaaS (Software as a service) is when programs are accessed remotely and subscribed to.
is when software is accessed remotely and on a subscription basis.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" sm-margin="0px 24px 16px 0px">
					My role
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					From the end of 2018 to the end of 2019, I served as a product designer. I was responsible for creating from scratch all product interfaces, identity, website page design and some templates.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Interfaces were created in Figma or directly in HTML and CSS.
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Description
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					StepFORM is a builder in which the user can create complex calculator forms with logic and formulas.{" "}
				</Text>
			</Section>
			<Section quarkly-title="Example" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Override slot="SectionContent" display="flex" grid-template-columns="1fr 640px 1fr" grid-gap="16px" />
				<Components.StepFormExample grid-column="1/3" border-width="1px" border-style="solid" border-color="--color-lightD2" />
				<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2" max-width="720px">
					Example of a form with calculation of the cost of building a house
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Advantages
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					In StepFORM, the user has a wide set of controls and flexible options for customizing these controls. This allows you to create unique forms with calculations.
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/9sHUc29LC1M"
					width="100%"
					modestbranding
					border-width="1px"
					border-color="--color-lightD2"
					border-style="solid"
				/>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					For example, it could be a complex calculation of the cost of repairs based on the square footage of a room, or ordering a pizza based on the quantity, type of pizza and diameter.{" "}
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/pY5MMMDU-Cw"
					width="100%"
					modestbranding
					border-width="1px"
					border-color="--color-lightD2"
					border-style="solid"
				/>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					You can make the form of several steps. For example, leave the calculation on the first step and filling in the contact details on the second step.
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/YfjbXX_yGOs"
					width="100%"
					modestbranding
					border-width="1px"
					border-color="--color-lightD2"
					border-style="solid"
				/>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					The user creates such a form and then places it on the website or as a link in social networks.
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/0Q8-y8ZCe_Y"
					width="100%"
					modestbranding
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Form calculators allow you to do some of the work automatically, give a preliminary estimate of the work, or collect data.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					It is also a good way to attract customers and increase sales. Since the customer does not want his labor to be wasted and he is inclined to continue to cooperate.
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Builder
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Make a user-friendly multi-page form builder with math, scoring, control customization, text and control values editor.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					Solution
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Did a great job of creating the interface:
				</Text>
				<List
					margin="6px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--lead21"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					color="--darkL2"
				>
					<Text margin="0px 0px 6px 0px">
						Design work with multi-page forms
					</Text>
					<Text margin="0px 0px 6px 0px">
						Adding controls
					</Text>
					<Text margin="0px 0px 6px 0px">
						Editing text
					</Text>
					<Text margin="0px 0px 6px 0px">
						{"    "}Designed and designed 18 controls and their customizations
					</Text>
					<Text margin="0px 0px 6px 0px">
						Controls editing options
					</Text>
					<Text margin="0px 0px 6px 0px">
						Design and logic of value scoring
					</Text>
					<Text margin="0px 0px 6px 0px">
						Design and logic of mathematical formulas to output the result
					</Text>
					<Text margin="0px 0px 6px 0px">
						Many boundary states, micro interactions and customizations
					</Text>
				</List>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-builder-empty-ru-1.png?v=2022-06-16T07:57:49.696Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						The base state of an empty project invites you to start working with the form
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-builder-adding-ru-2.png?v=2022-06-16T07:58:04.929Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						The discerning user has 18 different controls to create a survey
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-builder-text-edit-ru-3.png?v=2022-06-16T07:58:14.146Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Text controls have a built-in style editor
					</Override>
					<Override slot="Image" border-width="1px" border-style="solid" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-builder-settings-ru-4.png?v=2022-06-16T07:58:23.322Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Each control has its own unique settings on the “floating panel”
					</Override>
					<Override slot="Image" border-width="1px" border-style="solid" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-builder-image-size-ru-5.png?v=2022-06-16T08:18:53.661Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						For images, there is a handy way to adjust the size
					</Override>
					<Override slot="Image" border-width="1px" border-style="solid" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-builder-drag-n-drop-ru-6.png?v=2022-06-16T08:19:04.317Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Each control can be thrown to the desired location on the form
					</Override>
					<Override slot="Image" border-width="1px" border-style="solid" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-builder-paddings-ru-7.png?v=2022-06-16T08:19:14.195Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						You can customize internal indents
					</Override>
					<Override slot="Image" border-width="1px" border-style="solid" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-builder-scoring-ru-8.png?v=2022-06-16T08:19:22.370Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						There is a separate panel for managing calculations, where you can specify values and write a formula
					</Override>
					<Override slot="Image" border-width="1px" border-style="solid" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-builder-pages-ru-9.png?v=2022-06-16T08:32:32.908Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						For complex shapes, a breakdown into steps is devised
					</Override>
					<Override slot="Image" border-width="1px" border-style="solid" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Customizing the design and logic of the form
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Come up with and implement an interface to customize the design and logic of forms.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					SOLUTION
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Here I decided to design the settings so that the shape is visible at the same time. This allows you to visually control the styling process.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					In transition logic editing mode, unique numbers are shown near the form fields. This looks like an instruction when creating logic conditions.{" "}
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-logic-ru-1.png?v=2022-06-16T08:25:12.410Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Панель для редактирования логики переходов
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-design-ru-2.png?v=2022-06-16T08:25:20.249Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Настройка оформления формы
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Table of answers
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Create a visual way of displaying form responses, with sorting, filtering, multi-page and data-intensive options.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					Solution
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					I have presented the results in the form of a table. Since the number of answers will increase, and it is more convenient to scroll down, it is reasonable to show new answers in the form of rows.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					In the table, you can select which fields to show.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Sorting by field works when you click on the header in the table head.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					To select the answers you want, I added a column with checkboxes. Once selected, you can delete or download some of the answers.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					I got around the technical limitation of loading a large number of responses by adding multipage and select with the number of responses displayed.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-responces-empty-ru-1.png?v=2022-06-16T10:06:18.210Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						A state where there are no answers
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-responces-few-ru-2.png?v=2022-06-16T10:06:18.207Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Answers are shown as rows, questions in columns
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-responces-columns-ru-3.png?v=2022-06-16T10:07:13.877Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Selecting which columns to show
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-responces-sort-ru-4.png?v=2022-06-16T10:07:13.873Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Sorting occurs when you click on the title
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-responces-download-ru-5.png?v=2022-06-16T10:07:41.348Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						All answers can be saved as a file
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-responces-selected-actions-ru-6.png?v=2022-06-16T10:07:41.349Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						You can select the answers you want, download them, or delete them as needed
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Other service pages
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Add product wrapper to the designer, design sections:{" "}
				</Text>
				<List
					margin="6px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--lead28"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					color="--darkL2"
				>
					<Text margin="0px 0px 6px 0px">
						dashboard
					</Text>
					<Text margin="0px 0px 6px 0px">
						project settings
					</Text>
					<Text margin="0px 0px 6px 0px">
						sharing
					</Text>
					<Text margin="0px 0px 6px 0px">
						form preview
					</Text>
					<Text margin="0px 0px 6px 0px">
						account profile
					</Text>
				</List>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					SOLUTION
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Designed and formalized the necessary sections in all required states.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-dashboard-ru-1.png?v=2022-06-16T10:19:00.968Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Dashboard with card projects
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-settings-ru-1.png?v=2022-06-16T10:19:22.276Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Form settings
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-publication-ru-3.png?v=2022-06-16T12:40:54.776Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Form publishing options
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-profile-ru-6.png?v=2022-06-16T13:52:02.267Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Profile in pop-up sidebar
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-preview-phone-ru-5.png?v=2022-06-16T12:41:26.130Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Preview the survey on your smartphone screen
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-preview-tablet-ru-4.png?v=2022-06-16T12:41:03.068Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Survey preview on the tablet screen
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Main page
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Make the main product page, explain what the product is, what it is useful for, describe the main benefits, show examples.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					Solution
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Я придумал и спроектировал адаптивный дизайн главной страницы.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					На первом экране я описал пользователю куда он попал и что здесь можно делать.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Далее я расписал преимущества квизов и форм, показал различные примеры готовых форм, описал процесс создания, а также почему имеет смысл выбрать именно этот конструктор.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Для привлечения внимания посетителей на первом экране я сделал интересную анимацию со слоями формы: формула + элементы + значение = готовая форма.
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/0Z5za-PVgbk"
					width="100%"
					modestbranding
					border-width="1px"
					border-color="--color-lightD2"
					border-style="solid"
				/>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Список и предпросмотр шаблонов
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Для привлечения пользователей через органический поиск требуется создать открытый каталог шаблонов форм и квизов.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Сделал страницу со списком категорий и их шаблонов в виде карточек.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					В превью шаблона я поместил реальную форму, чтобы пользователь мог с ней взаимодействовать. Кнопки шеринга, чтобы пользователь мог поделится формой.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Если шаблон не подходит для пользователя, ему предлагаются близкие по тематике другие шаблоны.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Поскольку пользователь может зайти сюда не зная о StepFORM, в нижней части страницы я разместил вопросы и ответы.{" "}
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-template-list-ru-1.png?v=2022-06-16T14:45:07.610Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Список категорий и карточки шаблонов
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-template-details-ru-2.png?v=2022-06-16T14:45:07.613Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Страница с детальным описанием шаблона для поисковиков
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Тарифы
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Сделать дизайн для страницы тарифов. Нужно добавить таблицу сравнения и ответами на популярные вопросы.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Тарифы я представил в виде карточек, а сравнение в виде таблицы, что является стандартом для подобного рода продуктов.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					При клике на непонятный пункт сравнения, рядом с ним показывается подсказка
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-plans-ru-1.png?v=2022-06-16T14:52:39.210Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Карточки тарифов с кратким списком характеристик
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-plans-ru-2.png?v=2022-06-16T14:52:39.202Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Полный список сравнения тарифов
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Примеры форм и опросов
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Пара примеров для демонстрации того, какими разными они могут быть.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-example-ru-1.png?v=2022-06-16T14:58:12.730Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Форма заказа пиццы с блоком расчёта сверху и формой заказа снизу
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-example-ru-2.png?v=2022-06-16T14:58:12.715Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Форма для заказа расчёта стоимости строительства дома
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Логотип и фирменный стиль
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Я выбрал глубокий синий цвет как основу стиля. В дополнение к нему нейтральный серый. Основное шрифтовое семейство — Montserrat.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-logo-ru-1.svg?v=2022-06-16T15:13:15.011Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Логотипы с различными фонами в полном и сокращенном варианте
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-banner-ru-2.png?v=2022-06-16T15:14:56.526Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Пример баннера
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Конкурентная разведка
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					В ходе работы над продуктом, я изучил и опробовал 47 близких и дальних конкурентов. Лучшие подходы и практики я учитывал проектируя интерфейс StepFORM.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-competitors-1.png?v=2022-06-20T07:20:12.399Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Таблица конкурентов StepFORM
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Результаты
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Продукт пользуется популярностью в нише, имеет несколько десятков тысяч регистраций.
				</Text>
			</Section>
			<Components.PrevNextButtons>
				<Override slot="text">
					uKit Alt
				</Override>
				<Override slot="text1">
					WebAsk
				</Override>
				<Override slot="linkBox" href="/works/ukitalt" />
				<Override slot="linkBox1" href="/works/webask" />
			</Components.PrevNextButtons>
			<Components.Footer />
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(31525948, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
		</RawHtml>
	</Theme>;
});